<template>
  <div class="px-8 py-10 container" style="max-width: 1150px;margin:0 auto;">
    <div class="flex flex-wrap">
      <div class="w-full pb-30">
        <TitleComponent class="py-8" title="Reaction Detection" size="large" />
        <div
          class="flex flex-wrap mt-8 mb-4 px-6 py-10"
          style="background-color:#f7f5f4;"
        >
          <div class="w-full">
            <TitleComponent class="pb-2" title="Introduction" size="medium" />
            <p class="p-light mb-6">
              Reaction detection is a critical step in accurately completing
              sensitivity testing. Energy is imparted to a substance in multiple
              ways using primarily impact, friction, and electrostatic discharge
              (spark) methods in small-scale sensitivity testing. Once the
              energy has been imparted, the sample may or may not react. In the
              ET Users Group, the onset of reaction, or the energy level at
              which the material begins to show evidence of decomposition,
              energy release, or gaseous byproducts is considered a reaction or
              ‘Go’.
            </p>
            <p class="p-light mb-6">
              Subjective means of determining if the material reacts upon insult
              are and have been used for decades. These methods include
              primarily human detection (smoke, light, decomposition evidence,
              etc.) and recently imaging of the reaction and then interpretation
              of the image. There are non-subjective methods such as measuring
              the sound decibels, amount of gases generated, ejected particle
              velocities, electromagnetic radiation, and differences between
              pixel values.
            </p>
            <p class="p-light mb-4">
              For the ET Users Group Round Robin testing, the non-subjective
              methods used to determine if a reaction has occurred are:
            </p>
            <ul class="list-disc p-light ml-8 mb-6">
              <li>Gas analysis (&gt;1ppm CO over the baseline)</li>
              <li>
                Ejected particle velocities as determined using high-speed video
                (&gt;1000 inches per second, see Jetting discussion below)
              </li>
              <li>
                High-speed camera and the
                <a
                  class="link"
                  href="http://www.smsenergetics.com/test-equipment/godetect"
                  target="_blank"
                  rel="noopener noreferrer"
                  >GoDetect algorithm</a
                >
                for ESD (spark) testing
              </li>
            </ul>

            <TitleComponent class="pb-2" title="Jetting" size="medium" />

            <p class="p-light">
              When energetic substances (those with both a fuel and oxidizer
              present) react, they can generate significant quantities of gas.
              That gas expands and can force particles or material outwards.
              <a
                class="link"
                href="http://www.smsenergetics.com/"
                target="_blank"
                >Safety Management Services, Inc.</a
              >
              has documented the particle ejection velocities for several
              substances and found that particle speeds above 1000 inches per
              second when using the ABL Friction, BAM friction, or MBOM Impact
              machines indicate a reaction event has occurred, termed “Jetting”.
              Below are two plots showing the particle speeds observed at
              various heights and pressures for the MBOM Impact and ABL Friction
              machines for sand. The red lines indicate the worst possible case
              as judged by the maximum observed speed being 45 degrees out of
              the viewing plane.
            </p>
          </div>
          <div class="w-full md:w-1/2">
            <img src="@/assets/library/ABLFrictionSandTeflon-min.png" alt="" />
          </div>
          <div class="w-full md:w-1/2">
            <img src="@/assets/library/MBOMImpactSand-min.png" alt="" />
          </div>
          <div class="w-full">
            <p class="p-light mb-8">
              In the above plots, the frame rate of the camera was 2500 to 3000
              fps. The frame rate can be reduced such that an easy metric of
              determining whether the particulate ejection velocity is at least
              1000 inches per second. Those settings and description images for
              ABL Friction and MBOM Impact are given below. If the “jet” moves
              beyond the edge of the MBOM Impact anvil or the ABL Friction
              bulkhead, the particle velocity is at least 1000 inches per
              second.
            </p>
          </div>
          <div class="w-full md:w-1/2">
            <img
              src="@/assets/library/ABLFrictionHighSpeedJettingSettings-min.png"
              alt=""
            />
          </div>
          <div class="w-full md:w-1/2">
            <img
              src="@/assets/library/MBOMImpactHighSpeedJettingSettings-min.png"
              alt=""
            />
          </div>
        </div>
        <div
          class="flex flex-wrap mt-8 mb-4 px-6 py-10"
          style="background-color:#f7f5f4;"
        >
          <div class="w-full md:w-1/2">
            <TitleComponent class="pb-2" title="No Go Video" size="medium" />
            <div class="mb-4">
              <iframe
                class="m-auto"
                src="https://player.vimeo.com/video/144020469?autoplay=0&amp;autopause=0"
                allowfullscreen=""
                title="vimeo144020469"
                allow="autoplay; fullscreen"
                id="player_1"
                name="fitvid0"
              ></iframe>
            </div>
            <div class="mb-4">
              <iframe
                class="m-auto"
                src="https://player.vimeo.com/video/144020727?autoplay=0&amp;autopause=0"
                allowfullscreen=""
                title="vimeo144020727"
                allow="autoplay; fullscreen"
                id="player_2"
                name="fitvid1"
              ></iframe>
            </div>
            <div class="mb-4">
              <iframe
                class="m-auto"
                src="https://player.vimeo.com/video/144019019?autoplay=0&amp;autopause=0"
                allowfullscreen=""
                title="vimeo144019019"
                allow="autoplay; fullscreen"
                id="player_3"
                name="fitvid2"
              ></iframe>
            </div>
            <div class="mb-4">
              <iframe
                class="m-auto"
                src="https://player.vimeo.com/video/144019989?autoplay=0&amp;autopause=0"
                allowfullscreen=""
                title="vimeo144019989"
                allow="autoplay; fullscreen"
                id="player_4"
                name="fitvid3"
              ></iframe>
            </div>
          </div>
          <div class="w-full md:w-1/2">
            <TitleComponent
              class="pb-2"
              title="Go or Jetting Video"
              size="medium"
            />
            <div class="mb-4">
              <iframe
                class="m-auto"
                src="https://player.vimeo.com/video/144021200?autoplay=0&amp;autopause=0"
                allowfullscreen=""
                title="vimeo144021200"
                allow="autoplay; fullscreen"
                id="player_5"
                name="fitvid4"
              ></iframe>
            </div>
            <div class="mb-4">
              <iframe
                class="m-auto"
                src="https://player.vimeo.com/video/144022068?autoplay=0&amp;autopause=0"
                allowfullscreen=""
                title="vimeo144022068"
                allow="autoplay; fullscreen"
                id="player_6"
                name="fitvid5"
              ></iframe>
            </div>
            <div class="mb-4">
              <iframe
                class="m-auto"
                src="https://player.vimeo.com/video/144022215?autoplay=0&amp;autopause=0"
                allowfullscreen=""
                title="vimeo144022215"
                allow="autoplay; fullscreen"
                id="player_7"
                name="fitvid6"
              ></iframe>
            </div>
          </div>
          <div class="w-full">
            <TitleComponent
              class="pb-2"
              title="Supplemental Information"
              size="medium"
            />
            <p class="p-light">
              Note that there are materials that may be viewed as inert in that
              the fuel or oxygen isn’t in sufficient quantity to result in an
              energetic reaction. Despite not having close to an ideal ratio of
              fuel to oxidizer, the chemical bonds can be broken from an impact
              or other energy imparting event and subsequent reactions with
              oxygen in the air or formation of a more stable component can
              release energy/ gases.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TitleComponent from "@/components/TitleComponent";

export default {
  components: {
    TitleComponent
  },
  data: function() {
    return {
      showContent: false
    };
  }
};
</script>

<style lang="scss"></style>
